type MetricChangeProps = {
  metric: string;
  metricChange: number;
  giveTextColor?: boolean;
}

const MetricChangeHandler = ({ metric, metricChange, giveTextColor }: MetricChangeProps) => {

  let leadingString, initialUnit, followingUnit;

  if (metricChange > 0) {
    leadingString = "+";
  } else {
    leadingString = "-";
  }
  
  if (metric === "Sales") {
    initialUnit = "£";
  } else {
    followingUnit = "%";
  }

  return (
    <span className="text-widget-blue font-bold">
      {leadingString}
      {initialUnit}
      {metricChange.toLocaleString()}
      {followingUnit}
    </span>
  )

}

export default MetricChangeHandler;