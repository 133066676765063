
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LoggedInWrapper } from './auth';
import { ScreenProvider } from './context';

import MaintenancePage from './pages/maintenance';
import PurchasingPage from './pages/purchasing';
import DashboardPage from './pages/dashboard';
import ProductPage from './pages/products';
import ConfigPage from './pages/config';
import LoginPage from './pages/login';
import TermsPage from './pages/terms';
import EqsiPage from './pages/eqsi';

import PurchasingTrailer from './pages/purchasingtrailer';

function App() {
  return (
    <ScreenProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<LoggedInWrapper><DashboardPage /></LoggedInWrapper>} />
          <Route path="/maintenance" element={<LoggedInWrapper><MaintenancePage /></LoggedInWrapper>} />
          <Route path="/purchasing" element={<LoggedInWrapper><PurchasingPage /></LoggedInWrapper>} />
          <Route path="/products" element={<LoggedInWrapper><ProductPage /></LoggedInWrapper>} />
          <Route path="/eqsi" element={<LoggedInWrapper><EqsiPage /></LoggedInWrapper>} />
          <Route path="/terms" element={<LoggedInWrapper><TermsPage /></LoggedInWrapper>} />
          <Route path="/purchasingTrailer" element={<LoggedInWrapper><PurchasingTrailer /></LoggedInWrapper>} />
          <Route path="/config" element={<LoggedInWrapper><ConfigPage /></LoggedInWrapper>} />
        </Routes>
      </Router>
    </ScreenProvider>
  );
}

export default App;
