import React, { useState, useEffect } from 'react';


import LightMetricCard from '../components/widgets/lightMetricCard';
import PrimaryTable from '../components/widgets/primaryTable';
import CustomersCard from '../components/customer';
import SuppliersCard from '../components/supplier';

import { maintenanceData } from '../dummyData';

const MaintenancePage = () => {
  const tabOptions = ["Customers", "Suppliers", "Roles", "Users"];
  const [tab, setTab] = useState(tabOptions[0]);

  const [maintenanceCardOpen, setMaintenanceCardOpen] = useState(false);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-4 gap-4">
        <LightMetricCard title="Customers" count={1345} icon="user-tag" chartColor="blue" />
        <LightMetricCard title="Active Customers" count={14} icon="user-tie-hair" chartColor="red" />
        <LightMetricCard title="Suppliers" count={3820} icon="user-pen" chartColor="yellow" />
        <LightMetricCard title="Active Suppliers" count={4690} icon="user-crown" chartColor="green" />
      </div>

      {maintenanceCardOpen 
        ? <div className="flex-grow overflow-hidden">
            {tab === "Customers"
              ? <SuppliersCard closeCard={() => setMaintenanceCardOpen(false)} />
              : <SuppliersCard closeCard={() => setMaintenanceCardOpen(false)} />
            }
          </div>
        : <PrimaryTable
            source="Maintenance"
            itemData={maintenanceData}
            tabOptions={tabOptions}
            onClickEvent={() => setMaintenanceCardOpen(true)}
          />
      }
    </div>
  );
}

export default MaintenancePage;