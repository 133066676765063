import React, { useState, ReactNode, useRef } from 'react';
import './tooltip.css';

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  additionalClasses?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content, position = 'top', additionalClasses }) => {
  const [visible, setVisible] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      setVisible(true);
    }, 1500);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    setVisible(false);
  };

  return (
    <div
      className={`tooltip-wrapper ${additionalClasses}`}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <div className={`tooltip-box tooltip-${position}`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;