import { ucFirst } from "../../utils";
import SimpleBarChart from "./charts/barChart";
import SimpleLineChart from "./charts/lineChart";
import MetricChangeHandler from "./metricChangeHandler";

type ChartCardProps = {
  title: string;
  change: number;
  period: string;
  metric: string;
  chartData: any;
  chartType: "line" | "bar";
  chartColor: "pink" | "green" | "black";
  includeAverageLine?: boolean;
}

const ChartCard = ({ title, change, period, metric, chartData, chartType, chartColor, includeAverageLine }: ChartCardProps) => {
  return (
    <div className="dashboard-card p-4">
      <div className="flex justify-end items-center gap-2 mb-2">
        <i className="cursor-pointer fa-regular fa-pen-to-square" ></i>
      </div>

      {chartData.length > 0 
        ? <>
            <div className="bg-[#9AA3B820] w-full rounded-md p-4">
              <h3 className="font-bold mb-2">{ucFirst(period)}&nbsp;{ucFirst(metric)}</h3>
              {chartType === "line" ? <SimpleLineChart chartData={chartData} /> : <SimpleBarChart chartData={chartData} includeAverageLine={includeAverageLine} />}
            </div>

            <h2 className="font-bold mt-4 md:text-2xl">{title}</h2>
            <p className="text-muted text-xs"><MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />&nbsp;{change < 0 ? ` decrease` : `increase`} in {period} {metric}.</p>
          </>
        : <>
            <div className="flex flex-col gap-4 items-center justify-center w-full h-48 rounded-md p-4">
              <i className="fas fa-spinner fa-spin text-2xl md:text-4xl"></i>
              <h3 className="font-bold text-center">Loading {ucFirst(period)} data...</h3>
            </div>
          </>
      }

    </div>
  )

}

export default ChartCard;