import React, { useState, useEffect } from 'react';
import { Login } from '../auth';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    setError('');
    
    if (!email || !password) {
      setError('Email and password are required.');
      return;
    }

    if (email !== 'hdmAdmin24' || password !== process.env.REACT_APP_TEMP_USER_PASSWORD) {
      setError('Email or password is incorrect.');
      return;
    }

    Login();
    // Send user to home page
    window.location.href = '/';
  }


  return (
    <div className="h-screen w-screen grid md:grid-cols-2 bg-white">
      <img src="/login-hero.jpeg" alt="login" className="object-cover w-full h-full" />

      <div className="flex flex-col gap-2 justify-center items-center p-4 md:p-12">

        <img src="/hdm-logo.png" alt="logo" className="h-20" />

        <h3 className="text-2xl font-bold text-gray-800 mt-6  text-center">Sign In</h3>
        <p className="text-gray-600  text-center">Enter your email and password to Sign In</p>

        <div className="md:max-w-md mt-12">
          <input className="w-full p-2 border border-gray-200 rounded-md" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

          <input className="w-full p-2 border border-gray-200 rounded-md my-4" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

          <button onClick={() => handleLogin()} className="w-full bg-blue-600 text-white p-2 rounded-md">Sign In</button>

          {error && <p className="text-red-600 mt-4">{error}</p>}
        </div>

        <p className="text-gray-600 mt-4">Don't have an account? <a href="#" className="text-red-600">Sign Up</a></p>

      </div>
    </div>
  );
}

export default LoginPage;