import { useEffect } from 'react';
import { fetchProducts } from './api';
import { Product } from './types';

export const ucFirst = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

type KeyPressAction = () => void;

export const useKeyPress = (targetKey: string, action: KeyPressAction): void => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      console.log(event.key);
      if (event.key === targetKey) {
        event.preventDefault(); // Prevent default browser behavior if needed
        action(); // Execute the provided callback function
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [targetKey, action]);
};

export const fetchProductData = async () => {
  const data: Product[] = await fetchProducts();

  const products = data.filter((product: Product) => !product["Product code"].includes("(") && !product["Product code"].includes(")"));

  const productCategories: string[] = [];

  products.forEach((product: Product) => {
    if (!product["Product code"] || product["Product code"] === "") {
      console.log("Product code missing for product:", product);
    }

    if (!productCategories.includes(product["Prod Group"])) productCategories.push(product["Prod Group"]);
    if ((product["Bournemouth Stock"] && product["Bournemouth Stock"] !== "") &&  product["Sheffield Stock"] && product["Sheffield Stock"] !== "" && product["Hull Stock"] && product["Hull Stock"] !== "") {
      product["Total Stock"] = parseInt(product["Bournemouth Stock"]) + parseInt(product["Sheffield Stock"]) + parseInt(product["Hull Stock"]);
    }
    else product["Total Stock"] = 0;
  });

  return { products, productCategories }
}