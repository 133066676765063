import React, { useEffect, useState } from 'react';
import { useScreen } from '../../context';

import ChartCard from '../../components/widgets/chartCard';
import SimpleMetricCard from '../../components/widgets/simpleMetricCard';
import DoubleMetricCard from '../../components/widgets/doubleMetricCard';
import TopProducts from '../../components/widgets/topProductsTable';
import AnnouncementsCard from '../../components/widgets/announcementsCard';

import { dashboardBreakdownData, conversionHistoryData, topProductsData } from '../../dummyData';
import { fetchDashboardData } from '../../api';

import { processDataIntoDailySales, processDataIntoWeeklySales, parseOrderDate, processDataIntoMonthlySales } from './utils';

const DashboardPage = () => {
  const [fetching, setFetching] = useState(true);
  const [rollingDailySales, setRollingDailySales] = useState<{ name: string; "Last Week": number; "This Week": number; }[]>([]);
  const [rollingWeeklyOrders, setRollingWeeklyOrders] = useState<{ name: string; "Sales": number }[]>([]);
  const [rollingMonthlySales, setRollingMonthlySales] = useState<{ name: string; "Last Year": number; "This Year": number; }[]>([]);

  const { setScreenId } = useScreen();
  setScreenId("DASH");

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching dashboard data...');
        const fetchResponse = await fetchDashboardData();
        if (!fetchResponse) throw new Error('No data returned from fetchDashboardData');
        
        const orders = fetchResponse.orders;
        console.log("Received orders...");
        let ordersYTD = orders.filter((order: any) => new Date(order["Order Date"]).getFullYear() === new Date().getFullYear());
        let ordersPreviousYear = orders.filter((order: any) => new Date(order["Order Date"]).getFullYear() === new Date().getFullYear() - 1);
        ordersYTD.forEach((order: any) => order["formattedDate"] = parseOrderDate(order["Order Date"]));
        ordersPreviousYear.forEach((order: any) => order["formattedDate"] = parseOrderDate(order["Order Date"]));

        console.log("Processing data...");
        setRollingMonthlySales(processDataIntoMonthlySales(ordersPreviousYear, ordersYTD));
        setRollingWeeklyOrders(processDataIntoWeeklySales(ordersPreviousYear, ordersYTD));
        setRollingDailySales(processDataIntoDailySales(ordersYTD));
        console.log("Data processed.");
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl md:text-4xl font-bold">Dashboard</h1>

      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="grid md:grid-cols-3 gap-4 md:col-span-2">
          <SimpleMetricCard
            identifier='Total'
            metric='Orders'
            count={281}
            change={55}
            period='week'
            icon='cart-shopping'
            chartColor='black'
          />

          <SimpleMetricCard
            identifier='Total'
            metric='Sales'
            count={160000}
            change={3}
            period='week'
            icon='cash-register'
            chartColor='pink'
          />

          <SimpleMetricCard
            identifier='Total'
            metric='Profit'
            count={34000}
            change={1}
            period='week'
            icon='arrow-trend-up'
            chartColor='green'
          />
        </div>

        <DoubleMetricCard
          identifier='My'
          metricOne='Orders'
          metricOneChange={12}
          metricTwo='Sales'
          metricTwoChange={15121}
          icon='chart-line'
          chartColor='blue'
        />

        <ChartCard 
          title="HDM Daily Sales"
          change={15} period="daily" 
          metric="sales" 
          chartData={rollingDailySales}
          chartType='bar'
          chartColor='green'
        />

        <ChartCard 
          title="HDM Weekly Sales"
          change={15} period="weekly" 
          metric="sales" 
          chartData={rollingWeeklyOrders}
          chartType='bar'
          chartColor='pink'
          includeAverageLine={true}
        />

        <ChartCard 
          title="HDM Monthly Sales"
          change={15} period="monthly" 
          metric="sales"
          chartData={rollingMonthlySales}
          chartType='line'
          chartColor='black'
        />

      </div>
      
      <div className="grid md:grid-cols-3 gap-4 flex-grow mt-4">
        <div className="md:col-span-2">
          <TopProducts
            title="Top Selling Products"
            productData={topProductsData}
          />
        </div>
        
        <AnnouncementsCard
          announcements={[
            { author: "Adam F", date: "22/05/24 3:12pm", content: "Thanks for all the hard work during stocktake. We would like to take you all for a drink as a thank you and it will be this Friday at 8! See you there. Adam"
            },
            { author: "Claire S", date: "22/05/24 1:06pm", content: "Please can we start to upsell fixing mounts with all panels. We are missing a big opportunity on the smaller items and need to understand where each customer is currently purchasing. "
            },
            { author: "Tayah B", date: "22/05/24 9:01pm", content: "Make sure to check SolarScape before committing to larger orders.We need to start to utilise the buys ins! Tayah"
            }
          ]}
        />
      </div>
    </div>
  );
}

export default DashboardPage;