import React, { useState, useEffect, act } from 'react';
import { useKeyPress, fetchProductData } from "../utils";
import { useScreen } from '../context';

import PrimaryTable from './widgets/primaryTable';
import Pagination from './pagination';
import SearchBar from './searchBar';

import { eqsiData } from '../dummyData';

import { Product } from '../types';

import { ucFirst } from '../utils';

type NewEQSIprops = {
  customerData: any;
  closeCard: () => void;
  preConfirmationBasket: {product: Product, quantity: number, basketPrice: number}[];
}

const SalesOrderConfirmationScreen = ({ customerData, closeCard, preConfirmationBasket}: NewEQSIprops) => {
  const tabOptions = ["All", "Quotes", "Sales Orders", "Invoiced", "Completed"];
  const [productData, setProductData] = useState([] as any[]);
  const [previewProductId, setPreviewProductId] = useState(null as null | string);
  const [basket, setBasket] = useState(preConfirmationBasket as {product: Product, quantity: number, basketPrice: number}[]);
  const [basketStats, setBasketStats] = useState({ subtotal: 0, profit: 0, grossProfit: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [orderData, setOrderData] = useState({
    "Order Number": "135293", 
    "Payment Days": "Normal", 
    "Additional Discount": 0, 
    "Agreed With": "Tom @ Green Installations",
    "Contact Name": "Conor Dean",
    "Contact Number": "0333 455 6777",
    "Delivery Address": "123 Any Road, London",
    "Postcode": "L3 4TH",
    "Delivery Date": "15/09/2024", 
    "Courier": "HDM Courier"
  } as any);
  console.log(customerData)

  const { setScreenId } = useScreen();
  setScreenId("EQSI-SO")
  
  const clearEQSI = () => {
    setProductData([]);
    setPreviewProductId(null);
    setBasket([]);
    setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {
  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["Product code"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["Product code"]);
    }
  }
  useKeyPress('ArrowDown', downAction);

  useEffect(() => {
    console.log(filteredAndSearchedProducts);
  }, [filteredAndSearchedProducts]);

  const handleProductSelect = (id: string) => {
    const latestProductData = [...productData];

    console.log("Handle Product Select: ", id, latestProductData);
    const product = latestProductData.find((product) => product["Product code"] === id);
  
    const itemIndex = basket.findIndex((item) => item.product["Product code"] === id);
    if (itemIndex > -1) {
      const updatedBasket = [...basket];
      updatedBasket[itemIndex].quantity += 1;
      setBasket(updatedBasket);
    } else {
      let basketPrice = product["Terms Discount"] ? parseFloat((product["Trade Price"] - (product["Trade Price"] * product["Terms Discount"])).toFixed(2)) : product["Trade Price"];
      setBasket([...basket, { product, quantity: 1, basketPrice }]);
      setActiveBasketItem(id);
    }
  };

  useEffect(() => {
    console.log(basket);
    // Calculate subtotal based on each product's trade price and quantity
    let subtotal = 0;
    let cost = 0
    basket.forEach((item) => {
      subtotal += item.basketPrice * item.quantity;
      cost += item.product["Cost Price"] * item.quantity;
    });

    let profit = subtotal - cost;
    let grossProfit = (profit / cost) * 100;

    setBasketStats({ subtotal, profit, grossProfit });
  }, [basket]);
  

  const removeItemFromBasket = (id: string) => {
    console.log(id, activeBasketItem)
    const updatedBasket = [...basket];
    const indexOfActiveItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
    const indexOfItem = basket.findIndex((item) => item.product["Product code"] === id);

    console.log(indexOfItem, indexOfActiveItem, basket.length - 1);

    if (indexOfItem === indexOfActiveItem) {
      indexOfActiveItem === basket.length - 1
        ? setActiveBasketItem(basket[indexOfItem - 1].product["Product code"])
        : setActiveBasketItem(basket[indexOfItem + 1].product["Product code"])
    }
    console.log(indexOfItem, basket[indexOfItem + 1]);
    updatedBasket.splice(basket.findIndex((item) => item.product["Product code"] === id), 1);
    setBasket(updatedBasket);
  }

  const inputField = (label: string, orderDataKey: string) => (<>
    <div className="label">{label}</div>
    <input 
      type="text" 
      className="input" 
      placeholder={`${label}...`} 
      value={orderData[orderDataKey]}
      onChange={(e) => setOrderData({...orderData, [orderDataKey]: e.target.value})}
    />
  </>);

  return (
    <div className="flex flex-col gap-6 md:flex-row items-start w-full">
      <div className="dashboard-card flex-grow">
        {basket.map((item, index) => (
          <div 
            key={index} 
            className={`cursor-pointer border-b border-gray-200 p-4 ${activeBasketItem === item.product["Product code"] ? "bg-[#F2F4f7]" : ""}`}
          >
            <div className="flex items-start gap-4">
              <img src={item.product.Pict} alt={item.product.Description} className="w-16 h-16 rounded-md" />
              <div className="flex flex-col gap-2" onClick={() => setActiveBasketItem(item.product["Product code"])}>
                <div>
                  <h3 className="text-blue-500 text-xs font-semibold">{item.product["Product code"]}</h3>
                  <p className="text-sm font-semibold">{item.product.Description}</p>
                </div>

                <div className="flex gap-2 items-center justify-start">
                  <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                    <div className="border-gray-300 border-r px-1">
                      <p className="text-sm">£</p>
                    </div>
                    <div className="border-gray-300 border-r px-1">
                      <input 
                        type="number" 
                        value={item.basketPrice} 
                        style={{ width: `${item.basketPrice.toString().length * 9 + 25}px` }}
                        onChange={(e) => {
                          const updatedBasket = [...basket];
                          updatedBasket[index].basketPrice = parseFloat(e.target.value);
                          setBasket(updatedBasket);
                        }}
                        className="bg-transparent text-sm"
                      />
                    </div>
                    <div className="border-gray-300 border-r px-1">
                      <p className="text-sm">GP</p>
                    </div>
                    <div className="border-gray-300 px-1">
                      <p className="text-sm">{(((item.basketPrice - item.product["Cost Price"]) / item.product["Cost Price"])  * 100).toFixed(2)}%</p>
                    </div>
                  </div>

                  <div className="flex rounded-2xl px-1 border border-gray-300 bg-white items-center justify-start">
                    <button
                      onClick={() => {
                        const updatedBasket = [...basket];
                        updatedBasket[index].quantity -= 1;
                        setBasket(updatedBasket);
                      }}
                      disabled={item.quantity === 1}
                      className="border-gray-300 border-r px-1"
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={item.quantity}
                      className="px-1 bg-transparent text-center"
                      style={{ width: `${item.quantity.toString().length * 9 + 25}px` }}
                      onChange={(e) => {
                        const updatedBasket = [...basket];
                        updatedBasket[index].quantity = parseInt(e.target.value);
                        setBasket(updatedBasket);
                      }}
                    />
                    <button
                      onClick={() => {
                        const updatedBasket = [...basket];
                        updatedBasket[index].quantity += 1;
                        setBasket(updatedBasket);
                      }}
                      className="border-gray-300 border-l px-1"
                    >
                      +
                    </button>
                  </div>
                  
                </div>
              </div>

              <button 
                className=""
                onClick={() => {removeItemFromBasket(item.product["Product code"])}}
              >
                <i className="fa-solid fa-trash"></i>
              </button>
            </div>

            {activeBasketItem === item.product["Product code"] && ( 
              <div className="table w-full text-left mt-4">
                <thead>
                  <tr>
                    <th className="border-b border-gray-400 text-sm">Date</th>
                    <th className="border-b border-gray-400 text-sm">Action</th>
                    <th className="border-b border-gray-400 text-sm">Price</th>
                    <th className="border-b border-gray-400 text-sm">Quantity</th>
                    <th className="border-b border-gray-400 text-sm">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p className="text-sm px-1">05/09/24</p></td>
                    <td><p className="text-sm px-1">Quote</p></td>
                    <td><p className="text-sm px-1">£13.43</p></td>
                    <td><p className="text-sm px-1">12</p></td>
                    <td><p className="text-sm px-1">£161</p></td>
                  </tr>
                  <tr>
                    <td><p className="text-sm px-1">01/09/24</p></td>
                    <td><p className="text-sm px-1">Sale</p></td>
                    <td><p className="text-sm px-1">£13.35</p></td>
                    <td><p className="text-sm px-1">100</p></td>
                    <td><p className="text-sm px-1">£1335</p></td>
                  </tr>
                  <tr>
                    <td><p className="text-sm px-1">28/08/24</p></td>
                    <td><p className="text-sm px-1">Quote</p></td>
                    <td><p className="text-sm px-1">£14</p></td>
                    <td><p className="text-sm px-1">1</p></td>
                    <td><p className="text-sm px-1">£14</p></td>
                  </tr>
                </tbody>
              </div> 
            )}
          </div>
        ))}
      </div>

      {basket.length > 0 && (
        <div className="rounded-l-xl overflow-y-scroll w-[450px] bg-white dashboard-card sticky top-10">
          <div className="mt-auto border-b p-4">
            <h3 className="font-semibold text-lg">Order Details</h3>

            <div className="form-container mt-4">
              {inputField("Order Number", "Order Number")}
              {inputField("Payment Days", "Payment Days")}
              {inputField("Additional Discount", "Additional Discount")}
              {inputField("Agreed With", "Agreed With")}

              <h3 className="font-semibold">Customer Details</h3><div />
              {inputField("Contact Name", "Contact Name")}
              {inputField("Contact Number", "Contact Number")}


              <h3 className="font-semibold">Delivery Details</h3><div />
              {inputField("Address", "Delivery Address")}
              {inputField("Postcode", "Postcode")}
              {inputField("Requested Date", "Delivery Date")}
              {inputField("Courier", "Courier")}
            </div>
          </div>


          
          <div className="flex justify-between mt-auto border-b p-4">
            <button 
              className="btn btn-primary w-full flex items-center justify-center uppercase font-extrabold hover:bg-black"
            >Add Order Note</button>

            <h3 className="font-semibold">Subtotal:</h3>
            <h3 className="font-semibold">£{basketStats.subtotal.toFixed(2)}</h3>
          </div>

          <div className="flex justify-between px-4 pt-4 pb-2">
            <h3 className="font-semibold">Profit:</h3>
            <h3 className="font-semibold">£{basketStats.profit.toFixed(2)}</h3>
          </div>
        

          <div className="flex justify-between px-4">
            <h3 className="font-semibold">Gross Profit:</h3>
            <h3 className="font-semibold">{basketStats.grossProfit.toFixed(2)}%</h3>
          </div>

          <div className="px-4 pb-4">
            <button className="btn btn-primary w-full mt-4 flex items-center justify-center uppercase font-extrabold hover:bg-black">Convert to Order</button>
          </div>

          <div className="grid md:grid-cols-2 gap-4 px-4 pb-4">
            <button className="btn btn-secondary w-full flex items-center justify-center uppercase font-extrabold">Send Quote</button>

            <button 
              className="btn w-full flex items-center justify-center uppercase font-extrabold bg-red-300 text-white"
            >
              Abandon
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalesOrderConfirmationScreen;