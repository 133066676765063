import { start } from 'repl';
import { Order } from '../../types';

export const parseOrderDate = (dateString: string) => {
  // Convert "02 Aug 24" to a Date object
  const [day, month, year] = dateString.split(' ');
  const date = new Date(`${day} ${month} 20${year}`); // Assuming the year is in the 2000s
  return date;
};

const getPreviousWeekRange = () => {
  const currentDate = new Date();
  
  const dayOfWeek = currentDate.getDay();
  const daysSinceLastSunday = dayOfWeek + 7;

  const endOfPreviousWeek = new Date(currentDate);
  endOfPreviousWeek.setDate(currentDate.getDate() - daysSinceLastSunday + 7);

  const startOfPreviousWeek = new Date(endOfPreviousWeek);
  startOfPreviousWeek.setDate(endOfPreviousWeek.getDate() - 6);

  startOfPreviousWeek.setHours(0, 0, 0, 0);
  endOfPreviousWeek.setHours(23, 59, 59, 999);

  return { startOfPreviousWeek, endOfPreviousWeek };
};

const getCurrentWeekRange = () => {
  const currentDate = new Date();
  
  const dayOfWeek = currentDate.getDay();
  const startOfCurrentWeek = new Date(currentDate);
  startOfCurrentWeek.setDate(currentDate.getDate() - dayOfWeek + 1);

  const endOfCurrentWeek = new Date(startOfCurrentWeek);
  endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6);

  startOfCurrentWeek.setHours(0, 0, 0, 0);
  endOfCurrentWeek.setHours(23, 59, 59, 999);

  return { startOfCurrentWeek, endOfCurrentWeek };
}

export const processDataIntoDailySales = (orders: Order[]) => {
  // Get the orders from the previous monday -> sunday period
  const { startOfPreviousWeek, endOfPreviousWeek } = getPreviousWeekRange();
  const { startOfCurrentWeek, endOfCurrentWeek } = getCurrentWeekRange();

  let ordersLastWeek: Order[] = [];
  let ordersThisWeek: Order[] = [];

  orders.forEach((order: Order) => {
    order["formattedDate"] = parseOrderDate(order["Order Date"]);

    if (order["formattedDate"] >= startOfPreviousWeek && order["formattedDate"] <= endOfPreviousWeek) {
      ordersLastWeek.push(order);
    }

    if (order["formattedDate"] >= startOfCurrentWeek && order["formattedDate"] <= endOfCurrentWeek) {
      ordersThisWeek.push(order);
    }
  });

  const rollingWeeklyTotals = [ { name: "Mon", "Last Week": 0, "This Week": 0 }, { name: "Tue", "Last Week": 0, "This Week": 0 }, { name: "Wed", "Last Week": 0, "This Week": 0 }, { name: "Thu", "Last Week": 0, "This Week": 0 }, { name: "Fri", "Last Week": 0, "This Week": 0 }, { name: "Weekend", "Last Week": 0, "This Week": 0 } ];

  ordersLastWeek.forEach((order: Order) => {
    const orderDate = parseOrderDate(order["Order Date"]);
    const dayOfWeek = orderDate.toLocaleDateString('en-GB', { weekday: 'short' }) as "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
    
    if (dayOfWeek === "Sat" || dayOfWeek === "Sun") {
      rollingWeeklyTotals["5"]["Last Week"] += order["Order Val"];
    } else {
      let index = rollingWeeklyTotals.findIndex((day) => day.name === dayOfWeek);
      rollingWeeklyTotals[index]["Last Week"] += order["Order Val"];
    }
  });

  ordersThisWeek.forEach((order: Order) => {
    const orderDate = parseOrderDate(order["Order Date"]);
    const dayOfWeek = orderDate.toLocaleDateString('en-GB', { weekday: 'short' }) as "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
    
    if (dayOfWeek === "Sat" || dayOfWeek === "Sun") {
      rollingWeeklyTotals["5"]["This Week"] += order["Order Val"];
    } 
    else {
      let index = rollingWeeklyTotals.findIndex((day) => day.name === dayOfWeek);
      rollingWeeklyTotals[index]["This Week"] += order["Order Val"];
    }
  });

  return rollingWeeklyTotals;
};

export const processDataIntoWeeklySales = (ordersPreviousYear: any, ordersYTD: any) => {

  let currentMonth = new Date().getMonth();
  let weeklyData = [] as any;
  let formattedWeeklyData = [] as any;

  if (currentMonth > 3) {
    ordersYTD.sort((a: any, b: any) => b.formattedDate - a.formattedDate);
    let weeksAgo = 0;
    let weeksIterated = [] as number[];
    let i = 0;

    let currentWeekRange = getCurrentWeekRange();
    let startOfCurrentWeek = new Date(currentWeekRange.startOfCurrentWeek);
    let endOfCurrentWeek = new Date(currentWeekRange.endOfCurrentWeek);
    let weekString = startOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }) + " - " + endOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });

    while (weeksAgo < 7) {
      if (weeksAgo > 0 && !weeksIterated.includes(weeksAgo)) {
        startOfCurrentWeek.setDate(startOfCurrentWeek.getDate() - (7 * weeksAgo));
        endOfCurrentWeek.setDate(endOfCurrentWeek.getDate() - (7 * weeksAgo));
        weekString = startOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' }) + " - " + endOfCurrentWeek.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
        weeksIterated.push(weeksAgo);
      }

      if (ordersYTD[i].formattedDate < startOfCurrentWeek) {
        weeksAgo++;
      }

      weeklyData[weekString] 
        ? weeklyData[weekString] += ordersYTD[i]["Order Val"] 
        : weeklyData[weekString] = ordersYTD[i]["Order Val"];

      i++;
    }

    console.log("Weekly data:", weeklyData);
    formattedWeeklyData = Object.keys(weeklyData).map((week) => { 
      return { name: week, "Sales": weeklyData[week] };
    });
  }

  return formattedWeeklyData.reverse();
};

export const processDataIntoMonthlySales = (ordersPreviousYear: Order[], ordersYTD: Order[]) => {
  const rollingMonthlySales = [ { name: "Jan", "Last Year": 0, "This Year": 0 }, { name: "Feb", "Last Year": 0, "This Year": 0 }, { name: "Mar", "Last Year": 0, "This Year": 0 }, { name: "Apr", "Last Year": 0, "This Year": 0 }, { name: "May", "Last Year": 0, "This Year": 0 }, { name: "Jun", "Last Year": 0, "This Year": 0 }, { name: "Jul", "Last Year": 0, "This Year": 0 }, { name: "Aug", "Last Year": 0, "This Year": 0 }, { name: "Sep", "Last Year": 0, "This Year": 0 }, { name: "Oct", "Last Year": 0, "This Year": 0 }, { name: "Nov", "Last Year": 0, "This Year": 0 }, { name: "Dec", "Last Year": 0, "This Year": 0 } ];

  const setMonthlyTotalForYear = (orders: Order[], key: "Last Year" | "This Year") => {
    orders.forEach((order: Order) => {
      let month = order.formattedDate.toLocaleDateString('en-GB', { month: 'short' });
      month = month.length > 3 ? month.slice(0, 3) : month;

      let index = rollingMonthlySales.findIndex((monthData) => monthData.name === month);
      rollingMonthlySales[index][key] += order["Order Val"];
    });
  }

  setMonthlyTotalForYear(ordersPreviousYear, "Last Year");
  setMonthlyTotalForYear(ordersYTD, "This Year");

  return rollingMonthlySales;
}
