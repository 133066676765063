import React, { useEffect, useState } from 'react';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PrimaryTable from '../components/widgets/primaryTable';
import NewEQSI from '../components/newEQSI';
import Tooltip from '../components/widgets/tooltip';
import { useScreen } from '../context';

import { eqsiData } from '../dummyData';

const EqsiPage = () => {
  const tabOptions = ["All", "Quotes", "Sales Orders", "Invoiced", "Completed"];
  const [tab, setTab] = useState(tabOptions[0] as string);
  const [newEQSIopen, setNewEQSIopen] = useState(false);
  const [fetching, setFetching] = useState(false);

  const { setScreenId } = useScreen();
  setScreenId("7");

  return (
    <div className="flex flex-col flex-grow gap-4">
      {!fetching && (
        newEQSIopen 
          ? <NewEQSI closeCard={() => setNewEQSIopen(false)} />
          : <>
              <div className="grid md:grid-cols-4 gap-4">
                <LightMetricCard title="Total Quotes" count={4690} icon="square-quote" chartColor="blue" />
                <LightMetricCard title="Sales Orders" count={281} icon="bag-shopping" chartColor="yellow" />
                <LightMetricCard title="Converted Quotes" count={14} icon="box-open-full" chartColor="black" />
                <LightMetricCard title="Completed" count={4690} icon="box-check" chartColor="green" />
              </div>

              <PrimaryTable
                source="EQSI"
                itemData={eqsiData}
                tabOptions={tabOptions}
                actionButtons={<>
                  <Tooltip content="Create a new ESQI for one of your customers." position="top" additionalClasses='h-full'>
                    <button className="btn btn-primary h-full" onClick={() => setNewEQSIopen(true)}>
                      <i className="fa-solid fa-plus"></i><span className="hidden md:block">&nbsp;&nbsp;EQSI</span>
                    </button>
                  </Tooltip>
                </>}
              />
            </>
      )}
    </div>
  );
}

export default EqsiPage;