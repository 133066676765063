import MetricChangeHandler from "./metricChangeHandler";

type DoubleMetricCardProps = {
  identifier: string;
  metricOne: string;
  metricOneChange: number;
  metricTwo: string;
  metricTwoChange: number;
  icon: string;
  chartColor: "pink" | "green" | "black" | "blue";
}

const DoubleMetricCard = ({ identifier, metricOne, metricOneChange, metricTwo, metricTwoChange, icon, chartColor }: DoubleMetricCardProps) => {
  return (
    <div className="dashboard-card p-4">
      <div className="flex justify-between items-center">

        <div className="">
          <h3 className="font-bold text-xl md:text-4xl">My Numbers</h3>
          <p className="text-muted text-xs mt-1">Ethan Curtis</p>
        </div>

        <div className="flex items-center justify-end text-right">
          <img src="/tayah.png" className="rounded-xl h-14 w-15" alt="profile" />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="flex items-center gap-2">
          <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
            <i className="fas fa-cart-shopping"></i>
          </div>

          <div>
            <h3 className="font-bold text-xl md:text-2xl">12</h3>
            <p className="text-muted text-xs mt-1">Orders</p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
            <i className="fas fa-cash-register"></i>
          </div>

          <div>
            <h3 className="font-bold text-xl md:text-2xl">15,121</h3>
            <p className="text-muted text-xs mt-1">Sales</p>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
            <i className="fas fa-arrow-trend-up"></i>
          </div>

          <div>
            <h3 className="font-bold text-xl md:text-2xl">281</h3>
            <p className="text-muted text-xs mt-1">Branches</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default DoubleMetricCard;