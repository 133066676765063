import MetricChangeHandler from "./metricChangeHandler";

type SimpleMetricCardProps = {
  identifier: string;
  count: number;
  change: number;
  period: string;
  metric: string;
  icon: string;
  chartColor: "pink" | "green" | "black" | "blue" | "yellow" | "red";
}

const SimpleMetricCard = ({ identifier, count, change, period, metric, icon, chartColor }: SimpleMetricCardProps) => {

  const includeCurrencySymbol = metric === "Sales" || metric === "Profit";

  return (
    <div className="dashboard-card p-4">
      <div className="flex justify-end items-center gap-2 mb-2">
        <i className="cursor-pointer fa-regular fa-pen-to-square" ></i>
      </div>

      <div className="flex justify-between items-center">

        <div className="">
          <h3 className="font-bold text-xl md:text-4xl">
            {includeCurrencySymbol && "£"}
            {count > 10000 ? `${(count / 1000).toFixed(0)}K` : count.toLocaleString()}
          </h3>
          <p className="text-muted text-xs mt-1">{identifier}&nbsp;{metric}&nbsp;this&nbsp;{period}.</p>
        </div>

        <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl">
          <i className={`fas fa-${icon}`} data-testid='icon-testid'></i>
        </div>
      </div>

      <p className="text-muted text-xs mt-6 md:mt-8">
        <MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />
        &nbsp;than last {period}.
      </p>
    </div>
  )

}

export default SimpleMetricCard;