import React, { useRef } from "react";

type VerticalTextInputProps = {
    label: string,
    readonly?: boolean,
    onValueChanged?: (newValue: string) => void,
    defaultValue?: string,
    placeholder?: string,
    labelColourClass?: string,
    labelFontClass?: string,
    labelClassName?: string,
    inputColourClass?: string,
    inputFontClass?: string,
    inputClassName?: string,
    multiline?: boolean,
    // Ignored if multiline is true.
    type?: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' | 'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'tel' | 'text' | 'time' | 'url' | 'week',
    // Ignored if multiline is true.
    pattern?: string,
    className?: string,
    inputId?: string,
    labelTestId?: string,
    inputTestId?: string,
    componentTestId?: string
}

const VerticalTextInput = ({
                                label, 
                                readonly = false, 
                                onValueChanged, 
                                defaultValue, 
                                placeholder = "Start typing...", 
                                labelColourClass = "text-[#8181A5]", 
                                labelClassName = "", 
                                labelFontClass = "font-bold",
                                inputColourClass = "text-[#8181A5]", 
                                inputFontClass = "font-normal",
                                inputClassName = "", 
                                multiline = false, 
                                type = "text", 
                                pattern, 
                                className = "",
                                inputId = (label[0].toLowerCase() + label.replace(/\b\w/g, char => char.toUpperCase()).replace(" ", "").slice(1)) + "Id",
                                labelTestId = (label.toLowerCase().replace(" ", "-").trim()) + "-label",
                                inputTestId = (label.toLowerCase().replace(" ", "-").trim()) + "-input",
                                componentTestId = (label.toLowerCase().replace(" ", "-").trim()) + "-component",

                            }: VerticalTextInputProps) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    let labelClass: string = (`${labelColourClass} ${labelFontClass} mb-1 text-sm w-full flex-grow ${labelClassName}`).trim();
    let inputClass: string = (`${inputColourClass} ${inputFontClass} ${multiline ? " h-20" : ""} text-sm resize-none w-full ${inputClassName}`).trim();

    const valueChangedInput: React.ChangeEventHandler<HTMLInputElement> = (e: React.ChangeEvent) => {
        if (onValueChanged) onValueChanged((e.target as HTMLInputElement).value);
    }

    const valueChangedTextArea: React.ChangeEventHandler<HTMLTextAreaElement> = (e: React.ChangeEvent) => {
        if (onValueChanged) onValueChanged((e.target as HTMLTextAreaElement).value);
    }

    const refocusOnClick: React.MouseEventHandler<HTMLDivElement> = (e: React.MouseEvent) => {
        if (inputRef.current && !readonly) inputRef.current.focus();
    }

    return (
        <div className={(`flex flex-col m-1 ${multiline ? "" : "h-10 "} ${className}`).trim()} onClick={refocusOnClick} data-testid={componentTestId}>
            <label htmlFor={inputId} className={labelClass} data-testid={labelTestId}>{label}</label>

            {multiline ? (
                <textarea 
                    ref={inputRef as React.RefObject<HTMLTextAreaElement>}
                    className={inputClass} 
                    placeholder={placeholder} 
                    readOnly={readonly} 
                    value={defaultValue}
                    onChange={valueChangedTextArea}
                    id={inputId}
                    data-testid={inputTestId}/>
            ) : (
                <input 
                    ref={inputRef as React.RefObject<HTMLInputElement>}
                    className={inputClass} 
                    placeholder={placeholder} 
                    readOnly={readonly} 
                    type={type}
                    value={defaultValue}
                    onChange={valueChangedInput}
                    pattern={pattern}
                    id={inputId}
                    data-testid={inputTestId}/>
            )}
        </div>
    )
} 

export default VerticalTextInput;