export const maintenanceBreakdownData = [
  {
    metric: "Customers",
    period: "Week",
    value: 1.345,
    prevValue: 1.5,
    color: "#5E81F4",
    unit: null
  },
  {
    metric: "Suppliers",
    period: "Week",
    value: 3.820,
    prevValue: 6,
    color: "#F4BE5E",
    unit: null
  },
  {
    metric: "Users",
    period: "Week",
    value: 4690,
    prevValue: 17500,
    color: "#FF808B",
    unit: "£"
  },
  {
    metric: "Users",
    period: "Week",
    value: 3820,
    prevValue: 4500,
    color: "#7CE7AC",
    unit: "£"
  },
]

export const dashboardBreakdownData = [
  {
    metric: "Total Quotes",
    period: "Week",
    value: 1.345,
    prevValue: 1.5,
    color: "#5E81F4",
    unit: null
  },
  {
    metric: "Total Sales Orders",
    period: "Week",
    value: 3.820,
    prevValue: 6,
    color: "#F4BE5E",
    unit: null
  },
  {
    metric: "Converted Quotes",
    period: "Week",
    value: 4690,
    prevValue: 17500,
    color: "#FF808B",
    unit: "£"
  },
  {
    metric: "Completed",
    period: "Week",
    value: 3820,
    prevValue: 4500,
    color: "#7CE7AC",
    unit: "£"
  },
]

export const conversionHistoryData = [
  {
    values: [
      { value: 1.3, prevValue: 3 },
      { value: 3, prevValue: 10 }
    ],
  },
  {
    values: [
      { value: 1.8, prevValue: 3 },
      { value: 2.5, prevValue: 10 }
    ],
  },
  {
    values: [
      { value: 2.2, prevValue: 3 },
      { value: 5.5, prevValue: 10 }
    ],
  },
  {
    values: [
      { value: 1.4, prevValue: 3 },
      { value: 6, prevValue: 10 }
    ],
  },
  {
    values: [
      { value: 2, prevValue: 3 },
      { value: 3, prevValue: 10 }
    ],
  }
]

export const topProductsData = [
  {
    product: {
      name: "GivEnergy 2.6kWh Li-Ion Battery",
      image: "givEnergy2p6",
      ytdOrders: 8232,
    },
    sales: 130992,
    profit: 45600,
    orders: 13,
    prevOrders: 15
  },
  {
    product: {
      name: "Solax X1 G4-Hybrid 3.7 D HV Single Phase Inverter",
      image: "solax",
      ytdOrders: 12821,
    },
    sales: 80250,
    profit: 20200,
    orders: 40,
    prevOrders: 45
  },
  {
    product: {
      name: "DURACELL EV Charger",
      image: "duracell",
      ytdOrders: 2421,
    },
    sales: 40600,
    profit: 10430,
    orders: 54,
    prevOrders: 25
  },
  {
    product: {
      name: "Dura5 Battery",
      image: "dura5",
      ytdOrders: 5921,
    },
    sales: 35200,
    profit: 17824,
    orders: 5,
    prevOrders: 7
  },
]

export const productsData = [
  {
    product: {
      name: "DG Series 410W Panel",
      image: "dg410",
      brand: "Hyundai",
    },
    SKU: 302012,
    category: "Panels",
    stock: 36,
    price: 66.66,
    status: "Low Stock"
  },
  {
    product: {
      name: "X1-2.0 Mini 2.0kW Inverter",
      image: "x120mini",
      brand: "SolaX",
    },
    SKU: 302011,
    category: "Inverter",
    stock: 204,
    price: 316.75,
    status: "Published"
  },
  {
    product: {
      name: "LifePO4 Battery",
      image: "lifepo4",
      brand: "Sunsynk",
    },
    SKU: 302002,
    category: "Battery",
    stock: 48,
    price: 1145.00,
    status: "Draft"
  },
  {
    product: {
      name: "PureCharger 7KW-T",
      image: "purecharger7kwt",
      brand: "PureDrive",
    },
    SKU: 301901,
    category: "EV Charge",
    stock: 401,
    price: 540.00,
    status: "Published"
  },
  {
    product: {
      name: "160W Foldable Panel",
      image: "160wfoldable",
      brand: "V-Tac",
    },
    SKU: 301900,
    category: "Panels",
    stock: 120,
    price: 204.00,
    status: "Published"
  },
  {
    product: {
      name: "Cable 1ph 2.0m Land...",
      image: "cable1ph2m",
      brand: "Enphase",
    },
    SKU: 301881,
    category: "Cable",
    stock: 432,
    price: 30.57,
    status: "Published"
  },
  {
    product: {
      name: "End Clamp 702-0193",
      image: "endclamp7020193",
      brand: "Mounting Systems",
    },
    SKU: 301643,
    category: "Mounting",
    stock: 1,
    price: 2.02,
    status: "Low Stock"
  },
  {
    product: {
      name: "LifePO4 Battery",
      image: "lifepo4",
      brand: "Duracell",
    },
    SKU: 301600,
    category: "Battery",
    stock: 347,
    price: 1995.00,
    status: "Published"
  },
  {
    product: {
      name: "X1-2.0 Mini 2.0kW Inverter",
      image: "x120mini",
      brand: "SolaX",
    },
    SKU: 302011,
    category: "Inverter",
    stock: 204,
    price: 316.75,
    status: "Published"
  },
  {
    product: {
      name: "LifePO4 Battery",
      image: "lifepo4",
      brand: "Sunsynk",
    },
    SKU: 302002,
    category: "Battery",
    stock: 48,
    price: 1145.00,
    status: "Draft"
  },
  {
    product: {
      name: "PureCharger 7KW-T",
      image: "purecharger7kwt",
      brand: "PureDrive",
    },
    SKU: 301901,
    category: "EV Charge",
    stock: 401,
    price: 540.00,
    status: "Published"
  },
  {
    product: {
      name: "160W Foldable Panel",
      image: "160wfoldable",
      brand: "V-Tac",
    },
    SKU: 301900,
    category: "Panels",
    stock: 120,
    price: 204.00,
    status: "Published"
  },
  {
    product: {
      name: "Cable 1ph 2.0m Land...",
      image: "cable1ph2m",
      brand: "Enphase",
    },
    SKU: 301881,
    category: "Cable",
    stock: 432,
    price: 30.57,
    status: "Published"
  },
  {
    product: {
      name: "End Clamp 702-0193",
      image: "endclamp7020193",
      brand: "Mounting Systems",
    },
    SKU: 301643,
    category: "Mounting",
    stock: 1,
    price: 2.02,
    status: "Low Stock"
  },
  {
    product: {
      name: "LifePO4 Battery",
      image: "lifepo4",
      brand: "Duracell",
    },
    SKU: 301600,
    category: "Battery",
    stock: 347,
    price: 1995.00,
    status: "Published"
  }
];

export const eqsiData = [
  {
    customer: {
      name: "Solar Installs Ltd",
      image: "solar_installs_ltd",
    },
    date: "18 May 2019",
    number: "AA-04-19-1890",
    amount: 1890.00,
    credit: 3495.00,
    status: ["Quote"],
    id: 1
  },
  {
    customer: {
      name: "PV Repair",
      image: "pv_repair",
    },
    date: "07 Jul 2019",
    number: "AA-04-19-1090",
    amount: 2190.00,
    credit: 18425.00,
    status: ["Sales Order", "Invoiced"],
    id: 2
  },
  {
    customer: {
      name: "SW Designs",
      image: "sw_designs",
    },
    date: "24 Jan 2019",
    number: "AA-04-12-2830",
    amount: 2450.00,
    credit: 2273.00,
    status: ["Quote Lost", "N/A"],
    id: 3
  },
  {
    customer: {
      name: "SolarTech",
      image: "solartech",
    },
    date: "12 Jul 2019",
    number: "AA-04-15-2132",
    amount: 1900.00,
    credit: 3495.00,
    status: ["Completed", "Paid"],
    id: 4
  },
  {
    customer: {
      name: "Renewa Ltd",
      image: "renewa_ltd",
    },
    date: "26 Oct 2019",
    number: "AA-04-19-1567",
    amount: 3550.00,
    credit: 3495.00,
    status: ["Sales Order", "Invoiced"],
    id: 5
  },
  {
    customer: {
      name: "SolarClipse",
      image: "solarclipse",
    },
    date: "22 Jul 2019",
    number: "AA-04-19-1983",
    amount: 1670.00,
    credit: 4195.00,
    status: ["Quote"]
  },
  {
    customer: {
      name: "NW Solar",
      image: "nw_solar",
    },
    date: "12 Mar 2019",
    number: "AA-04-11-3550",
    amount: 4800.00,
    credit: 3356.00,
    status: ["Completed", "Paid"],
    id: 6
  },
  {
    customer: {
      name: "PV Repair",
      image: "pv_repair",
    },
    date: "07 Jul 2019",
    number: "AA-04-19-1090",
    amount: 2190.00,
    credit: 18425.00,
    status: ["Sales Order", "Invoiced"],
    id: 7
  },
  {
    customer: {
      name: "SW Designs",
      image: "sw_designs",
    },
    date: "24 Jan 2019",
    number: "AA-04-12-2830",
    amount: 2450.00,
    credit: 2273.00,
    status: ["Quote Lost", "N/A"],
    id: 8
  },
  {
    customer: {
      name: "SolarTech",
      image: "solartech",
    },
    date: "12 Jul 2019",
    number: "AA-04-15-2132",
    amount: 1900.00,
    credit: 2273.00,
    status: ["Completed", "Paid"],
    id: 9
  },
  {
    customer: {
      name: "Renewa Ltd",
      image: "renewa_ltd",
    },
    date: "26 Oct 2019",
    number: "AA-04-19-1567",
    amount: 3550.00,
    credit: 3495.00,
    status: ["Sales Order", "Invoiced"],
    id: 10
  },
  {
    customer: {
      name: "SolarClipse",
      image: "solarclipse",
    },
    date: "22 Jul 2019",
    number: "AA-04-19-1983",
    amount: 1670.00,
    credit: 4195.00,
    status: ["Quote"],
    id: 11
  },
  {
    customer: {
      name: "NW Solar",
      image: "nw_solar",
    },
    date: "12 Mar 2019",
    number: "AA-04-11-3550",
    amount: 4800.00,
    credit: 3356.00,
    status: ["Completed", "Paid"],
    id: 12
  }
]

export const purchasingData = [
  {
    supplier: {
      name: "Growatt",
      image: "growatt",
    },
    date: "18 May 2019",
    number: "AA-04-19-1890",
    amount: 1890.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "GivEnergy",
      image: "givenergy",
    },
    date: "07 Jul 2019",
    number: "AA-04-19-1090",
    amount: 2190.00,
    status: ["Partial", "Scheduled"]
  },
  {
    supplier: {
      name: "Canadian Solar",
      image: "canadian_solar",
    },
    date: "24 Jan 2019",
    number: "AA-04-12-2830",
    amount: 2450.00,
    status: ["Partial", "Unpaid"]
  },
  {
    supplier: {
      name: "EcoFlow",
      image: "ecoflow",
    },
    date: "12 Jul 2019",
    number: "AA-04-15-2132",
    amount: 1900.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "Growatt",
      image: "growatt",
    },
    date: "26 Oct 2019",
    number: "AA-04-19-1567",
    amount: 3550.00,
    status: ["None Received", "Scheduled"]
  },
  {
    supplier: {
      name: "Victron",
      image: "victron",
    },
    date: "22 Jul 2019",
    number: "AA-04-19-1983",
    amount: 1670.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "Duracell",
      image: "duracell",
    },
    date: "12 Mar 2019",
    number: "AA-04-11-3550",
    amount: 4800.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "GivEnergy",
      image: "givenergy",
    },
    date: "07 Jul 2019",
    number: "AA-04-19-1090",
    amount: 2190.00,
    status: ["Partial", "Scheduled"]
  },
  {
    supplier: {
      name: "Canadian Solar",
      image: "canadian_solar",
    },
    date: "24 Jan 2019",
    number: "AA-04-12-2830",
    amount: 2450.00,
    status: ["Partial", "Unpaid"]
  },
  {
    supplier: {
      name: "EcoFlow",
      image: "ecoflow",
    },
    date: "12 Jul 2019",
    number: "AA-04-15-2132",
    amount: 1900.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "Growatt",
      image: "growatt",
    },
    date: "26 Oct 2019",
    number: "AA-04-19-1567",
    amount: 3550.00,
    status: ["None Received", "Scheduled"]
  },
  {
    supplier: {
      name: "Victron",
      image: "victron",
    },
    date: "22 Jul 2019",
    number: "AA-04-19-1983",
    amount: 1670.00,
    status: ["Received", "Paid"]
  },
  {
    supplier: {
      name: "Duracell",
      image: "duracell",
    },
    date: "12 Mar 2019",
    number: "AA-04-11-3550",
    amount: 4800.00,
    status: ["Received", "Paid"]
  }
]

export const maintenanceData = [
  {
    customer: {
      name: "Solar Installs Ltd",
      image: "solar_installs_ltd",
    },
    contactDetail: "0111 222 333",
    lastTraded: "19/06/2024",
    credit: 1890.00,
    ["Terms Level 1 & Level 2"]: ["National", "No Cust Terms"]
  },
  {
    customer: {
      name: "PV Repair",
      image: "pv_repair",
    },
    contactDetail: "0451 782 339",
    lastTraded: "14/05/2024",
    credit: 2190.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "Customer Terms"]
  },
  {
    customer: {
      name: "SW Designs",
      image: "sw_designs",
    },
    contactDetail: "0151 254 358",
    lastTraded: "17/12/2023",
    credit: 2450.00,
    ["Terms Level 1 & Level 2"]: ["Medium Installer", "Expired"]
  },
  {
    customer: {
      name: "SolarTech",
      image: "solartech",
    },
    contactDetail: "07954 321 958",
    lastTraded: "20/04/2024",
    credit: 1900.00,
    ["Terms Level 1 & Level 2"]: ["National", "No Cust Terms"]
  },
  {
    customer: {
      name: "Renewa Ltd",
      image: "renewa_ltd",
    },
    contactDetail: "07841 552277",
    lastTraded: "10/10/2023",
    credit: 3550.00,
    ["Terms Level 1 & Level 2"]: ["National", "Customer Terms"]
  },
  {
    customer: {
      name: "SolarClipse",
      image: "solarclipse",
    },
    contactDetail: "0333 487 5135",
    lastTraded: "20/06/2024",
    credit: 1670.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "No Cust Terms"]
  },
  {
    customer: {
      name: "NW Solar",
      image: "nw_solar",
    },
    contactDetail: "01785 142 851",
    lastTraded: "20/06/2024",
    credit: 4800.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "No Cust Terms"]
  },
  {
    customer: {
      name: "PV Repair",
      image: "pv_repair",
    },
    contactDetail: "0451 782 339",
    lastTraded: "14/05/2024",
    credit: 2190.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "Customer Terms"]
  },
  {
    customer: {
      name: "SW Designs",
      image: "sw_designs",
    },
    contactDetail: "0151 254 358",
    lastTraded: "17/12/2023",
    credit: 2450.00,
    ["Terms Level 1 & Level 2"]: ["Medium Installer", "Expired"]
  },
  {
    customer: {
      name: "SolarTech",
      image: "solartech",
    },
    contactDetail: "07954 321 958",
    lastTraded: "20/04/2024",
    credit: 1900.00,
    ["Terms Level 1 & Level 2"]: ["National", "No Cust Terms"]
  },
  {
    customer: {
      name: "Renewa Ltd",
      image: "renewa_ltd",
    },
    contactDetail: "07841 552277",
    lastTraded: "10/10/2023",
    credit: 3550.00,
    ["Terms Level 1 & Level 2"]: ["National", "Customer Terms"]
  },
  {
    customer: {
      name: "SolarClipse",
      image: "solarclipse",
    },
    contactDetail: "0333 487 5135",
    lastTraded: "20/06/2024",
    credit: 1670.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "No Cust Terms"]
  },
  {
    customer: {
      name: "NW Solar",
      image: "nw_solar",
    },
    contactDetail: "01785 142 851",
    lastTraded: "20/06/2024",
    credit: 4800.00,
    ["Terms Level 1 & Level 2"]: ["Local Fitter", "No Cust Terms"]
  }
];

export const termsItems = [
  {
    item: "Dura-i 3.6kW Single-Phase Hybrid",
    group: "Inverter",
    GP: 16
  },
  {
    item: "Give Gen 3.3.6kW Single-Phase Hybrid",
    group: "Inverter",
    GP: 23
  },
  {
    item: "Suns ECCO 3.6kW Single-Phase Hybrid",
    group: "Inverter",
    GP: 28
  },
  {
    item: "MountSys Bracket Hanger Bolt M10 Offset",
    group: "Mounting",
    GP: 17
  },
  {
    item: "MountSys Concr Anch Plate M12x110mm",
    group: "Mounting",
    GP: 14
  },
  {
    item: "AIKO 450W All-Black Solar Panel AIKO-A450",
    group: "Panel",
    GP: 6
  },
  {
    item: "CanSol HiKu 550W Silver-Framed Solar",
    group: "Panel",
    GP: 19
  },
  {
    item: "CanSol HiKu 555W Silver-Framed Solar",
    group: "Panel",
    GP: 15
  },
  {
    item: "JA Solar 405W Black-Framed Solar",
    group: "Panel",
    GP: 23
  },
  {
    item: "JA Solar 430W All-Black Solar",
    group: "Panel",
    GP: 7
  }
];