import React, { useState, useEffect, useRef } from 'react';

const ChatWidget = ({collapsed}: {collapsed: boolean}) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const chatMessage = (message: string, sender: string) => {
    // get current time in HH:MM format
    const currentTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    return (
      <div className={`flex gap-3 items-end ${sender === 'user' ? 'justify-end' : 'justify-start'}`}>
        <div className="bg-black w-12 h-12 flex items-center justify-center rounded-full"
          >
            <img src="/aiChat.png" alt="dAIve Wizard" className="h-6" />
        </div>

        <div className={`flex flex-col gap-1 max-w-[80%]`}>
          <div className={`p-2 px-3 rounded-t-lg ${sender === 'user' ? 'bg-[#E91F63] rounded-l-lg' : 'bg-black rounded-r-lg'}`}>
            <p className="text-white">{message}</p>
          </div>
          <p className="text-xs text-[#474444]">{sender === 'user' ? 'You' : 'dAIve Wizard'} - {currentTime}</p>
        </div>
      </div>
    )
  }

  const promptMessage = (message: string) => {
    return (
      <div className="flex gap-3 items-end justify-end">
        <div className="flex flex-col gap-1">
          <div className="p-2 rounded-t-lg bg-widget-blue text-widget-blue px-3 rounded-l-lg cursor-pointer">
            <p>{message}</p>
          </div>
        </div>
      </div>
    )
  }

  const handleClickOutside = (event: MouseEvent) => {
    // Add type check for event.target as well
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);
  
  return (
    <>
      {open 
        ? <div className="h-screen w-screen fixed top-0 bg-black bg-opacity-85 flex items-center justify-center"
          style={{zIndex: 9999}}>
            <div ref={modalRef} className="rounded-lg bg-[#FAFAFA] p-4 md:p-6 h-full flex-grow lg:max-w-[850px] md:max-h-[80vh] flex flex-col m-4 gap-4">

              <div className="flex-1 overflow-y-scroll flex flex-col justify-end invisible-scrollbar dashboard-card p-4 gap-4">
                {chatMessage("Hi there, I'm the dAIve Wizard! You can ask me anything about your products, customers, purchasing, EQSI and more. Type a message or select from one of the prompts below to get started.", 'bot')}

                {[  "Could you generate me a list of customers who haven't ordered in the last 6 months?",
                    "Who are our top 5 customers with the most orders this week?", 
                    "What are our top selling products today?", 
                    "Are any products low on stock?"
                  ].map((message, index) => ( promptMessage(message) ))}
              </div>

              <div className="flex gap-4 items-center dashboard-card">
                <input type="text" placeholder="Type a message" className="flex-grow p-2 rounded-lg" />
                <button className="border-l border-[#F0F0F0] text-[#474444] p-2 px-4">
                  <i className="fa-solid fa-paper-plane-top"></i>
                </button>
              </div>
            </div>
          </div>
        : <div className={`flex mt-auto gap-2 w-full items-center  rounded-md px-2 py-2 justify-center cursor-pointer ${collapsed ? "" : "border border-white"}`} onClick={() => setOpen(true)}>
            <img src="/aiChat.png" alt="dAIve Wizard" className="h-6" />
            <p className={`${collapsed ? "hidden" : "flex"}`}>AI Chat</p>
          </div>
      }
    </>
  );
}

export default ChatWidget;