import React, { useState, useEffect } from 'react';
import SimpleBarChart from './widgets/charts/barChart';

import { fetchCustomer } from '../api';

type SuppliersCardProps = {
  closeCard: () => void;
}

const SuppliersCard = ({ closeCard }: SuppliersCardProps) => {
  const tabOptions = ["Overview", "Supplier Notes", "Website Data"];
  const [tab, setTab] = useState(tabOptions[0]);
  const [customerData, setCustomerData] = useState({} as { ACCNO: string, NAME: string, TERMS_GROUP: string, PAY_TERMS: string, "CREDIT.LIMIT": string, REP: string, PHONE: string, ADDRESS: string[], AREA: string, SALES: string[], ytdSales: number });
  const [ytdChartData, setYtdChartData] = useState([] as { name: string, Sales: number }[]);
  const [fetchingData, setFetchingData] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCustomer();
        console.log(data);

        if (data) {
          let customerDataObj = data;
          let ytdSales = customerDataObj.SALES.slice(-7).reverse();
          customerDataObj.ytdSales = ytdSales.reduce((acc: number, val: string) => acc + parseFloat(val), 0);

          let date = new Date();
          const currentMonth = date.getMonth() + 1;
          const currentYear = date.getFullYear();
          
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
          let ytdData = [];
          let salesData = customerDataObj.SALES;
          
          for (let i = 0; i < salesData.length; i++) {
            let monthIndex = currentMonth - i - 1;
            let year = currentYear;
            
            if (monthIndex < 0) {
              monthIndex += 12;
              year -= 1;
            }
          
            let name = months[monthIndex] + " " + year.toString().slice(-2);
            ytdData.push({ name, Sales: parseFloat(salesData[salesData.length - 1 - i]) });
          }
          
          setYtdChartData(ytdData.reverse());
          setCustomerData(customerDataObj);
        }
      } catch (error) {
        console.error('There was a problem fetching accounts:', error);
      }
      setFetchingData(false);
    };

    fetchData();
  }, []);

  const primaryDetailsFields = [
    { label: "Supplier name", value: customerData.NAME },
    { label: "Supplier ID Account No", value: customerData.ACCNO },
    { label: "Buying Terms Group", value: customerData.TERMS_GROUP || "BUY1" },
    { label: "Payment Terms", value: customerData.PAY_TERMS || "45 Days" },
    { label: "Lead Time", value: "3 Days" }
  ]

  const contactDetailsFields = [
    { label: "Contact Name", value: customerData.REP },
    { label: "Contact Number", value: customerData.PHONE }
  ]

  const addressDetailsFields = [
    { label: "Address", value: customerData.ADDRESS },
    { label: "Postcode", value: customerData.AREA },
    { label: "Delivery Address", value: "Bournemouth" },
    { label: "Delivery Postcode", value: "BH1 1AA" }
  ]

  const financialDetails = [
    { label: "Settlement", value: "4%"},
    { label: "VAT", value: "GB 123 456 789" },
    { label: "Our Account No", value: "HDM001" },
    { label: "Currency", value: "GBP" },
    { label: "Auto Match", value: "Yes" },
  ]

  const otherDetails = [
    { label: "Supplier Type", value: "National" },
    { label: "Supplier Status", value: "Dispute" },
    { label: "Back Orders", value: "BUY1" },
    { label: "Stock", value: "45 Days" },
    { label: "Group Orders", value: "3 Days" },
    { label: "Central Purchasing", value: "BUY1"},
    { label: "Default Pay Type", value: "45 Days" },
    { label: "Default Branch", value: "3 Days" },
    { label: "Own Label", value: "N"},
  ]

  const purchasingEmailDetails = [
    { label: "Email Address", value: "DuracelllPurchasing@duracell.com"}
  ]

  const ytdFields = [
    { label: "Orders YTD", value: 40 },
    { label: "Sales YTD", value: "£" + customerData.ytdSales },
    { label: "Orders LYTD", value: 15 },
    { label: "Orders LYTD", value: "£" + 1500 }
  ]

  return (
    <div className="dashboard-card flex-grow p-4 flex flex-col">
      
      {fetchingData &&  (
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      )}

      {!fetchingData && (
      
        customerData 
        ? <>
            <div className="flex justify-between items-center gap-4">
              <h3 className="font-semibold text-lg text-center md:text-left">SUPPLIER</h3>

              <button onClick={() => closeCard()}>
                <i className="fa-solid fa-times"></i>
              </button>
            </div>
         

            <div className="hidden md:flex mt-4">
              {tabOptions.map((option, index) => (
                <button key={index} className={`px-2 pb-2 border-b-2 ${tab === option ? 'border-[#1366D9]' : 'border-[#F0F1F3]'} hover:border-[#1366D9]`} onClick={() => setTab(option)}>{option}</button>
              ))}
              <div className="flex-grow border-b-2 border-[#F0F1F3]" />
            </div>

            <select className="block md:hidden mt-4 pb-2 border-b-2 border-[#F0F1F3] px-2" onChange={(e) => setTab(e.target.value)}>
              {tabOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>

            {tab === "Overview" && (
              <div className="grid md:grid-cols-2 gap-6 mt-6">
                <div className="div">
                  <h3 className="font-semibold">Primary Details</h3>

                  <div className="form-container mt-4">
                    {primaryDetailsFields.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}

                    <h3 className="font-semibold">Contact Details</h3><div />

                    {contactDetailsFields.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}

                    <h3 className="font-semibold">Supplier Address</h3><div />

                    {addressDetailsFields.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}

                    <h3 className="font-semibold">Financial Details</h3><div />

                    {financialDetails.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}
                  </div>
                </div>

                <div className="flex flex-col items-start">
                  <div className="border border-[#9D9D9D] border-dashed rounded-md p-2">
                    <img src="/renewa_logo.png" alt="map" />
                  </div>

                  <div className="grid grid-cols-2 gap-4 my-6">
                    {ytdFields.map((field, index) => (
                      <div className="bg-[#F5F5FA] text-center rounded-md p-2">
                        <h3 className="font-bold">{field.value}</h3>
                        <p className="text-sm">{field.label}</p>
                      </div>
                    ))}
                  </div>

                  <div className="form-container mt-4 w-full mb-6">

                    <h3 className="font-semibold">Other Details</h3><div />

                    {otherDetails.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}

                    <h3 className="font-semibold">Purchasing Email Details</h3><div />

                    {purchasingEmailDetails.map((field, index) => (
                      <>
                        <div className="label">{field.label}</div>
                        <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                      </>
                    ))}
                  </div>

                  <div className="flex w-full gap-1 justify-center md:justify-end mt-auto">
                    <button className="btn btn-outline"><i className="fa-solid fa-pencil"></i>&nbsp;&nbsp;Save</button>
                    <button className="btn btn-outline">Download</button>
                    <button className="btn btn-outline">Cancel</button>
                  </div>
                </div>
              </div>
            )}

            {tab === "Contacts/Addresses" && (
              <div className="form-container p-4">

                <h3 className="font-semibold">Contact Details</h3><div />

                {contactDetailsFields.map((field, index) => (
                  <>
                    <div className="label">{field.label}</div>
                    <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                  </>
                ))}

                <h3 className="font-semibold">Customer Address</h3><div />

                {addressDetailsFields.map((field, index) => (
                  <>
                    <div className="label">{field.label}</div>
                    <input type="text" className="input" placeholder={`${field.label}...`} value={field.value} />
                  </>
                ))}
              </div>
            )}

            {tab === "Financials" && (
              <div className="w-100 p-4">
                <div className="flex gap-4 flex-col md:flex-row md:justify-between mb-4">
                  <h2 className="font-bold">Year to date sales</h2>
                </div>
                
                <SimpleBarChart chartData={[
                  { name: 'Jan', Sales: 100 },
                  { name: 'Feb', Sales: 200 },
                  { name: 'Mar', Sales: 300 },
                  { name: 'Apr', Sales: 400 },
                  { name: 'May', Sales: 500 },
                  { name: 'Jun', Sales: 600 },
                  { name: 'Jul', Sales: 700 },
                  { name: 'Aug', Sales: 800 },
                  { name: 'Sep', Sales: 900 },
                  { name: 'Oct', Sales: 1000 },
                  { name: 'Nov', Sales: 1100 },
                  { name: 'Dec', Sales: 1200 }
                ]} />

                <div className="grid md:grid-cols-2 gap-4 my-6">
                  {ytdFields.map((field, index) => (
                    <div className="bg-[#F5F5FA] text-center rounded-md p-4">
                      <h3 className="font-bold">{field.value}</h3>
                      <p className="text-sm">{field.label}</p>
                    </div>
                  ))}
                  </div>
              </div>
            )}
          </>
        :  <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
          </div>
      )}
    </div>
  );
}

export default SuppliersCard;