type Announcement = {
  author: string;
  content: string;
  date: string;
}

type AnnouncementsCardProps = {
  announcements: Announcement[];
}

const AnnouncementsCard = ({ announcements }: AnnouncementsCardProps) => {
  return (
    <div className="dashboard-card p-4">
      <div className="flex justify-between items-center gap-2">
        <h3 className="font-bold">Latest Announcements</h3>

        <i className="cursor-pointer fa-regular fa-pen-to-square" ></i>
      </div>

      <div className="announcements-container mt-2 overflow-y-scroll">
        {announcements.map((announcement, index) => (
          <div key={index} className="announcement">
            <div className="flex items-center justify-between gap-2">
              <p className="text-[#344767] font-bold">{announcement.author}</p>
              <p className="text-muted text-xs">{announcement.date}</p>
            </div>

            <p className="mt-2 text-sm text-[#344767]">{announcement.content}</p>
          </div>
        ))}
      </div>
    </div>
  )

}

export default AnnouncementsCard;