import React, { useEffect, useState } from 'react';
import { useScreen } from '../context';

import LightMetricCard from '../components/widgets/lightMetricCard';
import PrimaryTable from '../components/widgets/primaryTable';
import ProductCard from '../components/productCard';
import Tooltip from '../components/widgets/tooltip';

import { fetchProductData } from "../utils";

type Product = {
  "Cost Price": number;
  Description: string;
  Pict: string;
  "Prod Group": string;
  "Product code": string;
  "Trade Price": number;
};

const ProductPage = () => {
  const tabOptions = ["All Products", "Low Stock"];
  const [tab, setTab] = useState(tabOptions[0] as string);
  const [fetching, setFetching] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState(null as null | string);
  const [productData, setProductData] = useState<Product[]>([]);
  const [productCategories, setProductCategories] = useState<string[]>([]);

  const { setScreenId } = useScreen();
  setScreenId("2");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { products, productCategories } = await fetchProductData();
        setProductData(products);
        setProductCategories(productCategories);
        setFetching(false);
      } catch (error) {
        setFetching(false);
        console.error('There was a problem fetching products:', error);
      }
    };

    fetchData();
  }, [selectedProductId]);

  return (
    <div className="flex flex-col flex-grow gap-4">
      <div className="grid md:grid-cols-3 gap-4">
        <LightMetricCard title="Total Products" count={2500} icon="warehouse-full" chartColor="green" />
        <LightMetricCard title="Unpublished" count={0} icon="clock" chartColor="yellow" />
        <LightMetricCard title="Low Stock" count={0} icon="circle-exclamation" chartColor="red" />
      </div>
      
      {fetching && 
        <div className="flex justify-center items-center h-full">
          <i className="fa-solid fa-spinner-third animate-spin text-[#3A5BFF] text-2xl"></i>
        </div>
      }

      {!fetching && (
        selectedProductId
          ? <ProductCard productId={selectedProductId} closeCard={() => setSelectedProductId(null)} productCategories={productCategories} />
          : <>
              <PrimaryTable
                source="Products"
                itemData={productData}
                onClickEvent={(id: string) => setSelectedProductId(id)}
                tabOptions={tabOptions}
                actionButtons={<>
                  <Tooltip content="Left click here to add a new product to your system." position="top" additionalClasses='h-full'>
                    <button className="btn btn-primary h-full" onClick={() => setSelectedProductId("newProd")}>
                      <i className="fa-solid fa-plus"></i><span className="hidden md:block">&nbsp;&nbsp;Add Product</span>
                    </button>
                  </Tooltip>
                </>}
              />
            </>
      )}
    </div>
  );
}

export default ProductPage;