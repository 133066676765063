import React, { useEffect, useState } from 'react';

import { searchProducts } from "../api";

type Search = {
  initialData: any;
  setResults: (results: any) => void;
}

const SearchBar = ({ initialData, setResults }: Search) => {
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const executeSearch = async () => {

      const locallyFilteredItems = initialData?.filter((item: any) => {
        return Object.entries(item).some(([key, value]) => {
          if (key !== "Pict") {
            if (typeof value === 'string') {
              return value.toLowerCase().includes(searchTerm.toLowerCase());
            } else if (typeof value === 'object') {
              return value !== null && Object.values(value).some((nestedValue) => {
                return nestedValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
              });
            }
          }
          return false;
        });
      }) ?? [];

      let apiFilteredItems: [] = 
        (searchTerm.length > 2)
          ? await searchProducts(searchTerm)
          : [];

      // Create a combined array where items only appear once
      const combinedItems = Array.from(new Set([...locallyFilteredItems, ...apiFilteredItems]));
      setResults(combinedItems);
    }

    executeSearch();
  }, [searchTerm]);

  return (
  <div className="search-bar h-[50px] dashboard-card px-4 flex-grow">
      <i className="fa-solid fa-magnifying-glass"></i>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
}

export default SearchBar;