import React, { createContext, useContext, useState, ReactNode } from 'react';

type ScreenContextType = {
  screenId: string;
  navCollapsed: boolean;
  setScreenId: (id: string) => void;
  toggleNavCollapsed: () => void;
}

const ScreenContext = createContext<ScreenContextType>({
  screenId: '',
  navCollapsed: false,
  setScreenId: () => {},
  toggleNavCollapsed: () => {},
});


export const ScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [screenId, setScreenId] = useState<string>('');
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);

  const toggleNavCollapsed = () => {
    setNavCollapsed(!navCollapsed);
  }

  return (
    <ScreenContext.Provider value={{ screenId, setScreenId, navCollapsed, toggleNavCollapsed }}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => useContext(ScreenContext);