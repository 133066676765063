const BASE_URL = process.env.REACT_APP_API_URL;

const fetchFromApi = async (apiPath: string, bodyData: {}) => {
  const response = await fetch(`${BASE_URL}${apiPath}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bodyData),
  });
  if (!response.ok) throw new Error('Network response was not ok');
  let data = await response.json();
  console.log(data);

  return data;
}

export const fetchProducts = async () => {
  try {
    const response = await fetchFromApi('products/list', {});
    console.log(response);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching products:', error);
  }
}

export const fetchProduct = async (productCode: string) => {
  try {
    const response = await fetchFromApi('products/fetch', { productCode });
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem fetching product:', error);
  }
}

export const searchProducts = async (searchQuery: string) => {
  try {
    const response = await fetchFromApi('products/search', { searchQuery });
    console.log(response.ExecuteCommand.Output);
    return response.ExecuteCommand.Output;
  }
  catch (error) {
    console.error('There was a problem searching products:', error);
  }
}

export const fetchCustomer = async () => {
  try {
    const response = await fetchFromApi('products/customer', {});
    console.log(response);
    return response.ReactCustomer[0];
  }
  catch (error) {
    console.error('There was a problem fetching customers:', error);
  }
}

export const fetchDashboardData = async () => {
  try {
    const response = await fetchFromApi('orders/fetch-dashboard', {});
    console.log(response);
    if (!response.orders) throw new Error('Missing data in response');
    return { orders: response.orders.ExecuteCommand.Output };
  }
  catch (error) {
    console.error('There was a problem fetching dashboard data:', error);
  }
}