export const colours = ["#3E56DA", "#F87171", "#34D399", "#FBBF24", "#818CF8", "#F472B6", "#10B981", "#3B82F6", "#F59E0B", "#6366F1", "#F472B6", "#34D399", "#F87171", "#FBBF24", "#818CF8", "#F59E0B", "#6366F1", "#10B981", "#3B82F6"];

export const yAxisFormatter = (value: number) => {
  if (value >= 1000000) {
    return `£${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `£${(value / 1000).toFixed(0)}K`;
  }
  return `£${value}`;
};

export const CustomLegend = ({ payload }: any) => {
  return (
    <div className="flex gap-4 justify-center">
      {payload.map((entry: any, index: number) => (
        <div key={`item-${index}`} className="flex gap-2 items-center">
          <div className="w-4 h-4" style={{ backgroundColor: colours[index] }}></div>
          <span className="font-semibold text-sm" style={{color: colours[index]}}>{entry}</span>
        </div>
      ))}
    </div>
  );
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  lineNames: string[];
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active = false, payload, label, lineNames }) => {
  if (active && payload && payload.length) {
    return (
      <div 
        className="custom-tooltip w-auto rounded-lg" 
        style={{
          position: 'absolute', 
          top: '-130px', 
          left: '0%', 
          transform: 'translateX(25%)', 
          backgroundColor: '#fff', 
          border: '1px solid #ccc', 
          padding: '10px', 
          borderRadius: '4px',
          whiteSpace: 'nowrap' // Ensure no wrapping occurs
        }}
      >
        <div>
          <span className="label font-semibold">{`${label}`}</span>
        </div>
        <div className="flex gap-2" style={{color: payload[0].color}}>
          <span>{lineNames[0]}:</span>
          <span>£{payload[0].value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
        </div>
        {payload[1] && (
          <div className="flex gap-2" style={{color: payload[1].color}}>
            <span>{lineNames[1]}:</span>
            <span>£{payload[1].value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
          </div>
        )}
      </div>
    );
  }

  return null;
};