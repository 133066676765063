import React, { useState, useEffect } from 'react';
import { useScreen } from '../context';

import { fetchProduct } from '../api';

type MaintenanceCardProps = {
  productId: string;
  closeCard: () => void;
  productCategories: string[];
}

const ProductCard = ({ productId, closeCard, productCategories }: MaintenanceCardProps) => {
  const tabOptions = ["Overview", "Descriptions", "Financials", "Stock Level Management"];
  const [tab, setTab] = useState(tabOptions[0]);
  const creatingNewProduct = productId === "newProd";
  const [fetchingData, setFetchingData] = useState(creatingNewProduct ? false : true);
  const [productData, setProductData] = useState({Pict: "", "Product code": "", "Supp": "", "Prod Group": "", "Trade Price": 0, "Cost Price":  0, Description: "",  "Supplier's pc": ""} as {"Cost Price":  number, Description: string, Pict: string, "Prod Group": string, "Product code": string, "Trade Price": number, "Supp": string, "Supplier's pc": string, [key: string]: any});

  const { setScreenId } = useScreen();
  setScreenId("3");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchProduct(productId);
        //console.log(data);
        setProductData(data[0]);
        setFetchingData(false);
      } catch (error) {
        setFetchingData(false);
        console.error('There was a problem fetching accounts:', error);
      }
    };

    productId && !creatingNewProduct && fetchData();
    //deleteLocalProducts();
  }, [productId, creatingNewProduct]);

  const addNewProduct = () => {
    // Retrieve any previously stored products from local storage
    const storedItems = JSON.parse(
      localStorage.getItem("localStorageProducts") || "[]"
    );
  
    // Add the new product to the list
    const updatedItems = [...storedItems, productData];
  
    // Store the updated list back in local storage
    localStorage.setItem("localStorageProducts", JSON.stringify(updatedItems));
    console.log("Product added to local storage");
  };

  const deleteLocalProducts = () => {
    localStorage.removeItem("localStorageProducts");
    console.log("Local products deleted");
  }

  const supplierDetailsFields = productData ? [
    { label: "Supplier name", value: productData["Supp"] },
    { label: "Contact Number", value: productData["Supplier's pc"]}
  ] : []

  const inputField = (label: string, productDataKey: string) => (<>
    <div className="label">{label}</div>
    <input 
      type="text" 
      className="input" 
      placeholder={`${label}...`} 
      value={productData[productDataKey]}
      onChange={(e) => setProductData({...productData, [productDataKey]: e.target.value})}
    />
  </>);

  return (
    <div className="dashboard-card flex-grow p-4 flex flex-col">
      
      {fetchingData &&  (
        <div className="flex flex-col justify-center items-center flex-grow">
          <i className="fa-solid fa-spinner fa-spin text-4xl"></i>
          <h3 className="font-semibold text-lg md:text-2xl ml-2">Fetching data...</h3>
        </div>
      )}

      {!fetchingData && (
      
        (productData || creatingNewProduct)
        ? <>
            <div className="flex items-start gap-4">
              {!creatingNewProduct && <img src={productData.Pict} alt={productData.Description} className="w-12 h-12 md:w-24 md:h-24 rounded-md" />}
              <div>
                {creatingNewProduct && <h3 className="uppercase text-sm text-center md:text-left">Add New Product</h3>}
                <h3 className="font-semibold text-xl text-center md:text-left mt-auto mb-3">{productData.Description}</h3>
              </div>
              

              <button className="ml-auto" onClick={() => closeCard()}>
                <i className="fa-solid fa-times"></i>
              </button>
            </div>
         

            <div className="hidden md:flex mt-4">
              {tabOptions.map((option, index) => (
                <button key={index} className={`px-2 pb-2 border-b-2 ${tab === option ? 'border-[#1366D9]' : 'border-[#F0F1F3]'} hover:border-[#1366D9]`} onClick={() => setTab(option)}>{option}</button>
              ))}
              <div className="flex-grow border-b-2 border-[#F0F1F3]" />
            </div>

            <select className="block md:hidden mt-4 pb-2 border-b-2 border-[#F0F1F3] px-2" onChange={(e) => setTab(e.target.value)}>
              {tabOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
              ))}
            </select>

            {tab === "Overview" && (
              <div className="flex w-100 gap-4 lg:gap-6 flex-grow flex-col xl:flex-row">
                <div className="flex-grow p-4">
                  <h3 className="font-semibold">Primary Details</h3>

                  
                    <div className="form-container mt-4">
                      {inputField("Product name", "Description")}
                      {inputField("Product ID", "Product code")}
                      <div className="label">Product Category</div>
                      <select 
                        className="input" 
                        value={productData["Prod Group"]} 
                        onChange={(e) => setProductData({...productData, "Prod Group": e.target.value})}
                      >
                        {productCategories.map((category, index) => (
                          <option key={index} value={category}>{category}</option>
                        ))}
                      </select>
                      {inputField("FIFO Cost", "Cost Price")}

                      <h3 className="font-semibold">Supplier Details</h3><div />

                      {inputField("Supplier name", "Supp")}
                      {inputField("Contact Number", "Supplier's pc")}
                    </div>
                </div>

                <div className="flex flex-col items-center shrink-0 md:pt-6">
                  <div className="flex w-full gap-1 justify-center md:justify-end mt-auto">
                    {creatingNewProduct
                      ? <button className="btn btn-primary" onClick={addNewProduct}><i className="fa-solid fa-plus"></i>&nbsp;&nbsp;Add Product</button>
                      : <button className="btn btn-outline"><i className="fa-solid fa-pencil"></i>&nbsp;&nbsp;Save</button>
                    }
                  </div>
                </div>
              </div>
            )}
          </>
        :  <div className="flex flex-col justify-center items-center flex-grow">
            <i className="fa-sharp fa-solid fa-circle-exclamation text-4xl"></i>
            <h3 className="font-semibold text-lg md:text-2xl ml-2">Couldn't fetch data...</h3>
          </div>
      )}
    </div>
  );
}

export default ProductCard;