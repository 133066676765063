import { ucFirst } from "../../utils";

type Product = {
  name: string;
  image: string;
  ytdOrders: number;
};

type ProductData = {
  product: Product;
  sales: number;
  profit: number;
  orders: number;
  prevOrders: number;
};

type TopProductsProps = {
  title: string;
  productData: ProductData[];
};

const isProduct = (value: any): value is Product => {
  return (value as Product).name !== undefined;
};

const TopProducts = ({ title, productData }: TopProductsProps) => {
  //console.log(productData);

  return (
    <div className="dashboard-card p-4 h-full">
      <div className="flex justify-between items-center gap-2">
        <h3 className="font-bold">{title}</h3>

        <i className="cursor-pointer fa-regular fa-pen-to-square" ></i>
      </div>

      <table className="table-auto w-full mt-4">
        <thead>
          <tr>
            {Object.keys(productData[0]).map((header, index) => (
              header !== 'prevOrders' &&
              <th key={index} className="text-left text-muted text-xs pl-2">{ucFirst(header)}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {productData.map((data, rowIndex) => (
            <tr key={rowIndex}>
              {Object.entries(data).map(([key, value], cellIndex) => (
                <td key={cellIndex} className="text-left text-sm">
                  {key === 'product' && isProduct(value) &&
                    <div className="flex items-center gap-2">
                      <img 
                        src={`/productImages/${value.image}.png`}
                        alt={value.name} 
                        className="w-10 h-10 rounded-md" 
                      />

                      <div>
                        <p className="text-[#344767] text-bold">{value.name}</p>
                        <p className="text-xs">
                          <span className="text-green-600">{value.ytdOrders.toLocaleString()}</span>&nbsp;
                          <span className="text-muted">YTD orders</span>
                        </p>
                      </div>
                    </div>
                  }

                  {typeof value === 'number' && key !== 'prevOrders' && 
                    <span>
                      {key === 'sales' || key === 'profit' ? '£' : ''}
                      {value.toLocaleString()}
                      {key === 'orders' && data.prevOrders !== undefined &&
                        <>
                          &nbsp;
                          <i className={`fas text-xs fa-chevron-${value > data.prevOrders ? 'up' : 'down'} text-${value > data.prevOrders ? 'green' : 'red'}-600`} data-testid={`${rowIndex}-row-icon-testid`}/>
                        </>
                      }
                    </span>
                  }
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

}

export default TopProducts;